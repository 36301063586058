import Vue from 'vue'
import ModalCheckInfo from '@/views/modules/crm/leads/form/form_data/modal/modalCheckInfo.vue'
import { showMsgRequest, eventsCustomSelects, debounce } from '@core-custom/mixins/geral'
import
{
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  parsePhoneNumber,
} from 'libphonenumber-js/max'

export const mixFormGeral = {
  computed:
    {
      mqShallShowLeftSidebar: {
        get() {
          const namespace = (('NAMESPACE_SW025_FORM' in this) ? this.NAMESPACE_SW025_FORM : 'crm_leads_form')
          return this.$store.getters[`${namespace}/mqShallShowLeftSidebar`]
        },
        set(newValue) {
          const namespace = (('NAMESPACE_SW025_FORM' in this) ? this.NAMESPACE_SW025_FORM : 'crm_leads_form')
          this.$store.commit(`${namespace}/setMqShallShowLeftSidebar`, newValue)
        },
      },
      sizePaginationListviewResponsive() {
        const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
        return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
      },
    },
  methods: {
    ...showMsgRequest.methods,
    ...eventsCustomSelects.methods,
    renderHtmlToTxt(value) {
      let txt = value
      if (txt) {
        txt = this.$options.filters.nl2br(txt)
      }

      return txt || '-----'
    },
    renderObjToTxt(value, field) {
      let txt = value
      if ((value !== null) && (value !== undefined) && (value !== '') && (field in value)) {
        txt = value[field]
      }

      return txt || '-----'
    },
    renderArrayToTxt(value, field) {
      const aValue = value
      let txt = ''

      if ((aValue !== null) && (aValue !== undefined) && (aValue.length > 0)) {
        aValue.forEach(o => {
          if (field in o) {
            txt += ((txt) ? ', ' : '') + o[field]
          }
        })
      }

      return txt || '-----'
    },
    truncateTxt(text, num) {
      const newText = text.split('').slice(0, num).join('')
      return newText
    },
  },
  filters: {
    nl2br(text) {
      if (text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
      }

      return ''
    },
    getTxt(value) {
      if ((value !== null) && (value !== undefined) && (value !== '') && ('txt' in value)) {
        return value.txt
      }
      return '-----'
    },
    truncate(text, num) {
      const newText = text.split('').slice(0, num).join('')
      return newText
    },
    lowerBold(value) {
      if (!value) return ''
      return `<b>${value.toLowerCase()}</b>`
    },
  },
}

export const mixEventsInputPhone = {
  computed: {
    numberPhoneValidate() {
      try {
        if (this.form_data.sw025s05) {
          return this.$refs.sw025s05.phoneObject
        }
      } catch (err) {
        //
      }

      return null
    },
  },
  methods: {
    onDropdownOpenTelInput() {
      this.$nextTick(() => {
        const widthElInputTel = this.$refs.sw025s05.$el.offsetWidth
        const el = this.$refs.sw025s05.$refs.list
        el.querySelector('.vti__search_box').placeholder = this.$t('Digite o nome')
        el.querySelector('.vti__search_box').addEventListener('keydown', evt => {
          const charCode = (evt.which) ? evt.which : window.event.keyCode

          if (charCode === 187) {
            evt.preventDefault()
          }
        })

        el.style.width = `${widthElInputTel}px`
      })
    },
    inputValidateNumberPhone(obj) {
      const namespace = (('NAMESPACE_SW025_FORM' in this) ? this.NAMESPACE_SW025_FORM : 'crm_leads_form')

      if (obj?.phoneObject?.number !== undefined && obj.phoneObject.number !== '') {
        try {
          const phoneNumber = parsePhoneNumber(obj.phoneObject.number, obj.phoneObject?.countryCode || '')
          if (phoneNumber) {
            const sIsValidPhoneNumber = phoneNumber.isValid()
            if (sIsValidPhoneNumber === false) {
              this.$nextTick(() => {
                this.$store.dispatch(`${namespace}/updateFieldValue`, { field: 'sw025s05', value: '' })
              })
            } else {
              this.$nextTick(() => {
                this.$store.dispatch(`${namespace}/updateFieldValue`, {
                  field: 'sw025s05',
                  value: phoneNumber.formatInternational(),
                })
              })
            }
          }
        } catch (error) {
          //
        }
      } else {
        this.$nextTick(() => {
          this.$store.dispatch(`${namespace}/updateFieldValue`, { field: 'sw025s05', value: '' })
        })
      }
    },
    checkValidateNumberPhone($event, phoneObject) {
      this.configPhone.inputOptions.maxlength = 25
      try {
        const phoneNumber = parsePhoneNumber($event, phoneObject?.countryCode || '')
        if (phoneNumber) {
          const sIsPossiblePhoneNumber = isPossiblePhoneNumber(`${$event}0`) // Insert +1 character to simulate
          const sValidatePhoneNumberLength = validatePhoneNumberLength(`${$event}0`) // Insert +1 character to simulate

          if (sValidatePhoneNumberLength === 'TOO_LONG') {
            if (sIsPossiblePhoneNumber === false) {
              this.configPhone.inputOptions.maxlength = $event.length ?? 0
            }
          }
        }
      } catch (error) {
        //
      }
    },
  },
}

export const mixValidateContactsForm = {
  data() {
    return {
      checkPhoneExistInit: 0,
      checkEmailExistInit: 0,
    }
  },
  methods: {
    async checkEmailExist(e) {
      if ((e.relatedTarget !== null) && (e.relatedTarget.classList.contains('sw-button-action'))) {
        return
      }

      if ((this.form_data.sw025s06 !== '') && (this.checkEmailExistInit === 0)) {
        this.checkEmailExistInit = 1
        this.$root.$emit('app::loading', true)

        const namespace = (('NAMESPACE_SW025_FORM' in this) ? this.NAMESPACE_SW025_FORM : 'crm_leads_form')

        await this.$store.dispatch(`${namespace}/checkExistAllMyLeadsByEmail`, this.form_data.sw025s06).then(async res => {
          this.$root.$emit('app::loading', false)

          if (res.error === 1) {
            if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
              const actionModal = new Promise(resolve => {
                this.$root.$emit('crmLeadsForm::showMsgDetailRequest', { pIcon: 'warning', pDetail: res.alerts }, resolve)
              })

              await actionModal.then(result => {
                if (result === false) {
                  this.$store.dispatch(`${namespace}/updateFieldValue`, { field: 'sw025s06', value: '' })
                  this.$nextTick(() => {
                    this.$refs.email.focus()
                  })
                }
              })
            }
          }
        }).catch(error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest(error)
        })

        this.checkEmailExistInit = 0
      }
    },
    async checkPhoneExist(e) {
      this.$nextTick(async () => {
        if ((e.relatedTarget !== null) && (e.relatedTarget.classList.contains('sw-button-action'))) {
          return
        }

        if ((e.relatedTarget !== null) && (e.relatedTarget.classList.contains('vti__dropdown-item'))) {
          return
        }

        if ((this.form_data.sw025s05 !== '') && (this.checkPhoneExistInit === 0)) {
          this.checkPhoneExistInit = 1

          let focusInputNext = true

          const namespace = (('NAMESPACE_SW025_FORM' in this) ? this.NAMESPACE_SW025_FORM : 'crm_leads_form')

          this.$root.$emit('app::loading', true)
          await this.$store.dispatch(`${namespace}/checkExistAllMyLeadsByPhone`, this.form_data.sw025s05).then(async res => {
            this.$root.$emit('app::loading', false)

            if (res.error === 1) {
              if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
                const actionModal = new Promise(resolve => {
                  this.$root.$emit('crmLeadsForm::showMsgDetailRequest', {
                    pIcon: 'warning',
                    pDetail: res.alerts,
                  }, resolve)
                })

                await actionModal.then(result => {
                  if (result === false) {
                    focusInputNext = false
                    this.$store.dispatch(`${namespace}/updateFieldValue`, { field: 'sw025s05', value: '' })
                    this.$nextTick(() => {
                      this.$refs.sw025s05.focus()
                    })
                  }
                })
              }
            }
          }).catch(error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequest(error)
            this.$refs.email.focus()
          })

          this.checkPhoneExistInit = 0

          if (focusInputNext === true) {
            this.$refs.email.focus()
          }
        }
      })
    },
  },
}

export const mixFormTabGeral = {
  methods: {
    checkOptionSelectedTipoCliente(option) {
      try {
        if (option !== null) {
          if ('disabled' in option) {
            if (option.disabled === true) {
              return false
            }
          }
        }
      } catch (err) {
        //
      }

      return true
    },
  },
}

export const mixEventsSelectCustom = {
  methods: {
    vSelectGetOptionKey(option) {
      if (typeof this.$refs.sw027s03 !== 'undefined') {
        const optionKeyNormalize = this.$refs.sw027s03.normalizeOptionForSlot(option)
        return this.$refs.sw027s03.getOptionKey(optionKeyNormalize)
      }

      return null
    },
    vNormalizeOptionForSlot(option) {
      if (typeof this.$refs.sw027s03 !== 'undefined') {
        return this.$refs.sw027s03.normalizeOptionForSlot(option)
      }

      return null
    },
    vSelectGetOptionLabel(option) {
      if (typeof this.$refs.sw027s03 !== 'undefined') {
        return this.$refs.sw027s03.getOptionLabel(option)
      }

      return null
    },
  },
}

export const mixEventsModals = {
  methods: {
    async showMsgDetailRequest(pIcon, pDetail, resolve) {
      this.$swal({
        title: '',
        icon: pIcon || '',
        html: '<div id="modal-check-info" />',
        showCloseButton: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Continuar com a duplicação'),
        cancelButtonText: this.$t('Voltar à edição'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
        willOpen: () => {
          const ComponentClass = Vue.extend(ModalCheckInfo)
          this.vueModalCheckInfoInstance = new ComponentClass({
            propsData: {
              itens: pDetail,
            },
          })
          this.vueModalCheckInfoInstance.$mount()
          document.getElementById('modal-check-info').appendChild(this.vueModalCheckInfoInstance.$el)
        },
      }).then(result => {
        this.vueModalCheckInfoInstance.$destroy()
        this.vueModalCheckInfoInstance = null

        if (result.value === true) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    async showMsgErrorRequestForm(error, errorFielsRequired) {
      let messageAlertError = ''

      if (error instanceof Error) {
        if (error.message !== '') {
          if (errorFielsRequired === true) {
            messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          } else {
            messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$t('Alerta')}</h3>`
          }

          messageAlertError += `<p class="mb-1">${error.message}</p>`
        }
      } else if (Object.keys(error).length > 0) {
        if ((Object.keys(error).length === 1) && ('message' in error) && (error.message === '')) {
          messageAlertError = ''
        } else {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          Object.keys(error).forEach(key => {
            if (error[key].length > 0) {
              error[key].forEach(txt => {
                messageAlertError += `<p class="mb-1">${txt}</p>`
              })
            }
          })
        }
      }

      if (messageAlertError !== '') {
        this.$swal({
          title: '',
          html: messageAlertError,
          icon: '',
          width: 600,
          confirmButtonText: this.$t('Fechar'),
          customClass: {
            confirmButton: 'btn btn-outline-primary btn-xs-block',
            htmlContainer: 'text-left',
            content: 'pl-0 pr-0 pl-sm-2 pr-sm-2',
          },
          buttonsStyling: false,
        })
      }
    },
  },
}

export const mixFormFonteContacto = {
  data() {
    return {
      isLoadingSW025s43: false,
      isLoadingSW025s45: false,
      listConsultoresApp: [],
      listLeadsContactsApp: [],
      showFonteContactoAO: false,
      showFonteContactoC: false,
      showFonteContactoM: false,
      showFonteContactoL: false,
      showFonteContactoSI: false,
    }
  },
  methods: {
    async fieldsExtrasFonteContacto(row) {
      /*
      Anuncio online
      Referenciado por consultor
      Teferenciado por hub
      Teferenciado por lead / contacto
      Site imobiliarios
      */

      this.showFonteContactoAO = false
      this.showFonteContactoC = false
      this.showFonteContactoM = false
      this.showFonteContactoL = false
      this.showFonteContactoSI = false

      this.form_data.sw025s221 = null
      this.form_data.sw025s222 = null
      this.form_data.sw025s43 = null
      this.form_data.sw025s44 = null
      this.form_data.sw025s63 = null

      if ((row !== null) && (row !== undefined)) {
        if ('sw023s06' in row) {
          if (row.sw023s06 === 'AO') {
            this.showFonteContactoAO = true
          }
          if (row.sw023s06 === 'C') {
            this.showFonteContactoC = true
          }
          if (row.sw023s06 === 'M') {
            this.showFonteContactoM = true
          }
          if (row.sw023s06 === 'L') {
            this.showFonteContactoL = true
          }
          if (row.sw023s06 === 'SI') {
            this.showFonteContactoSI = true
          }
        }
      }
    },

    searchConsultoresApp(text) {
      const namespace = (('NAMESPACE_SW001_DATA' in this) ? this.NAMESPACE_SW001_DATA : 'sw001')

      this.form_data.sw025s43 = null
      this.$refs.sw025s43Validation.reset()
      this.isLoadingSW025s43 = true
      this.$store.dispatch(`${namespace}/searchAllAgents`, { text, includeDelAndSusp: '' }).then(res => {
        this.listConsultoresApp = [{ data: res }]
        this.isLoadingSW025s43 = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    selectedSW025s43(row) {
      try {
        if (row.item.id) {
          this.form_data.sw025s43 = row.item
          this.form_data.sw025s43_txt = row.item.desc
        }
      } catch (err) {
        //
      }
    },
    clearSW025s43() {
      this.form_data.sw025s43 = null
      this.form_data.sw025s43_txt = null
      this.$refs.sw025s43.internalValue = ''
    },
    checkSelectedSW025s43() {
      if (this.form_data.sw025s43 === null) {
        this.clearSW025s43()
      }
    },
    tabSelectedSW025s43(e) {
      if (this.$store.getters['capacitor/isNative'] === true) {
        this.$refs.sw025s43.loading = true
        return
      }

      const {
        listeners, setChangeItem, getItemByIndex,
      } = this.$refs.sw025s43
      const item = getItemByIndex(this.$refs.sw025s43.currentIndex)
      if (!item) {
        this.$refs.sw025s43.loading = true
        return
      }

      e.preventDefault()
      setChangeItem(item, true)
      this.$refs.sw025s43.loading = true
      listeners.selected(true)
    },

    searchLeadsContactsApp(text) {
      const namespace = (('NAMESPACE_SW025_FORM_DATA' in this) ? this.NAMESPACE_SW025_FORM_DATA : 'sw025')

      this.form_data.sw025s45 = null
      this.$refs.sw025s45Validation.reset()
      this.isLoadingSW025s45 = true
      this.$store.dispatch(`${namespace}/searchLeadsContactoByUserAuth`, { text }).then(res => {
        this.listLeadsContactsApp = [{ data: res }]
        this.isLoadingSW025s45 = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    selectedSW025s45(row) {
      try {
        if (row.item.id) {
          this.form_data.sw025s45 = row.item
          this.form_data.sw025s45_txt = row.item.nome
        }
      } catch (err) {
        //
      }
    },
    clearSW025s45() {
      this.form_data.sw025s45 = null
      this.form_data.sw025s45_txt = null
      this.$refs.sw025s45.internalValue = ''
    },
    checkSelectedSW025s45() {
      if (this.form_data.sw025s45 === null) {
        this.clearSW025s45()
      }
    },
    tabSelectedSW025s45(e) {
      if (this.$store.getters['capacitor/isNative'] === true) {
        this.$refs.sw025s45.loading = true
        return
      }

      const {
        listeners, setChangeItem, getItemByIndex,
      } = this.$refs.sw025s45
      const item = getItemByIndex(this.$refs.sw025s45.currentIndex)
      if (!item) {
        this.$refs.sw025s45.loading = true
        return
      }

      e.preventDefault()
      setChangeItem(item, true)
      this.$refs.sw025s45.loading = true
      listeners.selected(true)
    },

    onSearchConsultoresAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchConsultoresAppSelect(loading, search, this)
      }
    },
    searchConsultoresAppSelect: debounce((loading, search, vm) => {
      const oThis = vm
      const namespace = (('NAMESPACE_SW001_DATA' in vm) ? vm.NAMESPACE_SW001_DATA : 'sw001')

      vm.$store.dispatch(`${namespace}/searchAllAgents`, { text: search, includeDelAndSusp: '' }).then(res => {
        oThis.listConsultoresApp = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.listConsultoresApp = []
        loading(false)
      })
    }, 500),

    onSearchLeadsContactsAppSelect(search, loading) {
      if (search.length) {
        loading(true)
        this.searchLeadsContactsAppSelect(loading, search, this)
      }
    },
    searchLeadsContactsAppSelect: debounce((loading, search, vm) => {
      const oThis = vm
      const namespace = (('NAMESPACE_SW025_FORM_DATA' in vm) ? vm.NAMESPACE_SW025_FORM_DATA : 'sw025')

      vm.$store.dispatch(`${namespace}/searchLeadsContactoByUserAuth`, { text: search }).then(res => {
        oThis.listLeadsContactsApp = res
        loading(false)
      }).catch(error => {
        vm.showMsgErrorRequest(error)
        oThis.listLeadsContactsApp = []
        loading(false)
      })
    }, 500),

    resetClearFieldsFonteContacto() {
      this.isLoadingSW025s43 = false
      this.isLoadingSW025s45 = false
      this.listConsultoresApp = []
      this.listLeadsContactsApp = []
      this.showFonteContactoAO = false
      this.showFonteContactoC = false
      this.showFonteContactoM = false
      this.showFonteContactoL = false
      this.showFonteContactoSI = false
    },
  },
}

export const _ = null
